<template>
    <div>
        <AddTrioProfiles v-if="displaySelectInstructor" @close="displaySelectInstructor = false"
            :profile="selectedProfile" :key="componentKey" :type="selectedType"
            :idTrainingAction="props.idTrainingAction" :metierId="selectedMetier" @confirm="$emit('confirm')"
            :referentId="selectedReferent" />
        <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
            :detail="notificationMessage" @close="showNotification = false" />
        <ConfirmationDialog @confirm="deleteItem(idTypeToEdit)" v-if="displayConfirmDeleteDialog"
            :title="confirmTexts[0].title" :message="confirmTexts[0].message"
            :primaryActionLabel="confirmTexts[0].primaryActionLabel"
            :secondaryActionLabel="confirmTexts[0].secondaryActionLabel" @close="displayConfirmDeleteDialog = false" />
        <ConfirmationDialog @confirm="removeInstructor(selectedProfile, selectedMetier, selectedReferent)"
            v-if="displayConfirmDeleteInstructor"
            :title="confirmTexts[1].title + ' ' + instructor_name + ' du parcours de ' + student_name"
            :message="confirmTexts[1].message" :primaryActionLabel="confirmTexts[1].primaryActionLabel"
            :secondaryActionLabel="confirmTexts[1].secondaryActionLabel"
            @close="displayConfirmDeleteInstructor = false" />
        <ul role="list"
            class="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
            <li v-for="person in profiles" :key="person.email">
                <div class="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6 items-center">
                    <div class="flex min-w-0 gap-x-4 items-center">
                        <img class="h-12 w-12 flex-none rounded-full bg-gray-50" :src="person.profile_picture_url"
                            alt="" v-if="person.profile_picture_url" />
                        <UserCircleIcon class="h-12 w-12 text-gray-300 -ml-1 flex-none rounded-full" aria-hidden="true"
                            v-else />
                        <div class="min-w-0 flex-auto">
                            <p class="text-sm font-semibold leading-6 text-gray-900">
                                {{ person.user_name }}
                                <!-- Todo: check values when endpoint updated-->
                            </p>
                            <span :class="'bg-role' + person.role.id"
                                class="mr-2 inline-flex items-center rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700">

                                {{ person.role_name }}
                            </span>
                        </div>
                    </div>
                    <div class="ml-auto flex max-h-[2rem]" v-if="person.role_name === 'Apprenant·e'">
                        <button v-if="!person.pivot?.metier_instructor_id" class="btn-read"
                            @click.prevent="selectedProfile = person.id; displaySelectInstructor = true; selectedType = 'metier'; selectedReferent = person.pivot?.referentr_instructor_id; selectedMetier = person.pivot?.metier_instructor_id; componentKey++">
                            <UserPlusIcon class="h-5 w-5 mr-1" /> formateur
                            métier
                        </button>
                        <span v-else class="flex items-center mr-3 badge-default">
                            <UserIcon class="h-4 w-4 text-indigo-600" /><span
                                class="mr-1 font-semibold text-indigo-700">métier :
                            </span><span v-if="person.pivot?.metier_instructor_id">{{
                                person.metier_instructor_name }}</span>
                            <XMarkIcon class="h-4 w-4 flex-none text-gray-400 ml-2" aria-hidden="true"
                                @click.prevent="selectedProfile = person.id; displayConfirmDeleteInstructor = true; selectedType = 'metier'; selectedReferent = person.pivot?.referent_instructor_id; selectedMetier = null; instructor_name = person.metier_instructor_name; student_name = person.user_name" />
                        </span>
                        <button v-if="!person.pivot?.referent_instructor_id" class="btn-read"
                            @click.prevent="selectedProfile = person.id; displaySelectInstructor = true; selectedType = 'referent'; selectedMetier = person.pivot?.metier_instructor_id; selectedReferent = person.pivot?.referent_instructor_id; componentKey++">
                            <UserPlusIcon class="h-5 w-5 mr-1" /> formateur
                            référent
                        </button>
                        <span v-else class="flex items-center mr-3 badge-default">
                            <UserIcon class="h-4 w-4 text-indigo-600" /><span
                                class="mr-1 font-semibold text-indigo-700">référent :
                            </span><span v-if="person.pivot?.referent_instructor_id">{{
                                person.referent_instructor_name }}</span>
                            <XMarkIcon class="h-4 w-4 flex-none text-gray-400 ml-2" aria-hidden="true"
                                @click.prevent="selectedProfile = person.id; displayConfirmDeleteInstructor = true; selectedType = 'metier'; selectedReferent = null; selectedMetier = person.pivot?.metier_instructor_id; instructor_name = person.referent_instructor_name; student_name = person.user_name" />
                        </span>
                    </div>
                    <div class="flex shrink-0 items-center gap-x-4">
                        <XMarkIcon class="h-5 w-5 flex-none text-gray-400" aria-hidden="true"
                            @click.prevent="displayConfirmDeleteDialog = true; typeEdition = 'creation'; idTypeToEdit = person.id" />
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref } from 'vue';
import { UserCircleIcon, XMarkIcon } from '@heroicons/vue/24/solid';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import client from '@/api/client';
import { UserPlusIcon, UserIcon } from '@heroicons/vue/24/outline';
import AddTrioProfiles from '@/components/training-actions/AddTrioProfiles.vue';

const displayConfirmDeleteDialog = ref(false);
const props = defineProps({
    profiles: [Array, Object],
    idTrainingAction: [Number, String],
});

const displaySelectInstructor = ref(false);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const idTypeToEdit = ref(null);
const selectedMetier = ref(null);
const selectedReferent = ref(null);
const selectedProfile = ref(null);
const componentKey = ref(0);
const displayConfirmDeleteInstructor = ref(false);
const instructor_name = ref(null);
const student_name = ref(null);

const confirmTexts = [
    {
        title: 'Détacher ce profil de cette action ?',
        message: 'Voulez-vous vraiment retirer ce profil de cette action de formation',
        primaryActionLabel: 'supprimer',
        secondaryActionLabel: 'annuler'
    },
    {
        title: 'Détacher le formateur',
        message: 'Voulez-vous détacher ce profil du parcours de cet apprenant•e ?',
        primaryActionLabel: 'détacher',
        secondaryActionLabel: 'annuler'
    },
]
const emit = defineEmits(['confirm']);

const deleteItem = async (id) => {
    displayConfirmDeleteDialog.value = false;
    try {
        await client.delete(`/api/training-actions/${props.idTrainingAction}/remove-profile`, {
            data: {
                profile_id: id,
            },
        });
        emit('confirm');
    } catch (error) {
        console.error('Failed to delete profile:', error);
    }
};

const removeInstructor = async (student, metier, referent) => {
    let arrayToPost = {};
    arrayToPost.profile_id = student;
    arrayToPost.role_id = 2;
    arrayToPost.metier_instructor_id = metier;
    arrayToPost.referent_instructor_id = referent;

    try {
        const response = await client.put(`api/training-actions/${props.idTrainingAction}/update-profile`, arrayToPost);
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, le profil a bien été retiré';
        notificationMessage.value = '';
        emit('confirm');
        displayConfirmDeleteInstructor.value = false;
    } catch (error) {
        showNotification.value = true;
        console.error('Failed to attach profile', error);
        notificationType.value = 'error';
        notificationTitle.value = 'Erreur lors de l\'ajout. ';
        notificationTitle.value += error.response.data.message;
    }
}
</script>